import { Component, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { AmplitudeService } from '../../services/amplitude.service';
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';
import { MatchesService } from '../../services/matches.service';
import { SidenavService } from '../../services/sidenav.service';
import { StandingsService } from '../../services/standings.service';
import { UsersService } from '../../services/users.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  sidenav: MatSidenav;
  title: string;
  loggedUser: any;
  standings: any;
  user: any;
  currentTimestamp: number;
  firstRoundIniTimestamp: number;
  finalResult: any;
  canEdit: boolean;
  isMe: boolean = false;
  isAdmin: boolean = false;

  private userId: string;

  constructor(
    private sidenavService: SidenavService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private usersService: UsersService,
    private matchesService: MatchesService,
    private standingsService: StandingsService,
    private router: Router,
    private apiService: ApiService,
    private amplitudeService: AmplitudeService
  ) {
    this.sidenav = this.sidenavService.getSidenav();
    this.currentTimestamp = moment().unix();
    this.loggedUser = this.authService.getUser();
    this.isAdmin = this.authService.isAdmin();
    this.title = this.userId ? this.user.name || this.user.email : 'Mi perfil';
    this.standings = {
      pos: '--',
      points: '--',
      matchesOk: '--',
      sniper: '--',
    };
  }

  async ngOnInit() {
    this.userId = this.route.snapshot.params['id'];

    if (this.userId) {
      this.user = await this.usersService.get(this.userId);
    } else {
      this.user = this.authService.getUser();
      this.userId = this.user._id;
    }

    this.isMe = this.loggedUser._id === this.user._id;
    this.canEdit = this.authService.isAdmin() || this.isMe;
    // this.final_result = this.standingsProv.get_final_result();
    let rounds = await this.matchesService.getRounds();
    this.firstRoundIniTimestamp = rounds[0].iniTimestamp;
    this.standings = await this.standingsService.getUser(this.userId);

    if (!this.isMe) {
      this.amplitudeService.logEvent('HomeVisited', { user: this.user.email });
    }
  }

  toggleMenu() {
    this.sidenavService.toggle();
  }

  showRules() {
    this.router.navigate([`/rules`]);
  }

  showPoll() {
    this.router.navigate([`/poll/${this.user._id}`]);
  }

  /**
   * Handle file from browsing
   */
  fileBrowseHandler(event: any) {
    const files = event.target.files;

    if (files.length === 1 && files[0].type.indexOf('image/') === 0) {
      const fileObj = files[0];

      this.apiService
        .uploadFile(`/users/upload-avatar/${this.user._id}`, fileObj)
        .then((user) => {
          this.user = user;
        });
    }
  }
}
