import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AmplitudeService } from '../../services/amplitude.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  email: string = '';
  name: string = '';
  code: string = '';
  password: string = '';
  confirmPassword: string = '';

  constructor(
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private router: Router,
    private amplitudeService: AmplitudeService
  ) {}

  ngOnInit(): void {}

  signup(): void {
    if (this.password !== this.confirmPassword) {
      this.snackBar.open(
        'La contraseña y la confirmación no coinciden.',
        'Cerrar',
        { duration: 5000 }
      );
      return;
    }

    const data = {
      email: this.email,
      name: this.name,
      code: this.code,
      password: this.password,
    };

    this.blockUI.start();
    this.authService
      .register(data)
      .then(() => {
        this.blockUI.stop();
        this.snackBar.open('Te has registrado correctamente', 'Cerrar', {
          duration: 5000,
        });
        this.router.navigate(['/login']);
        this.amplitudeService.logEvent('SignUp', { result: 'success' });
      })
      .catch((error) => {
        this.blockUI.stop();
        this.snackBar.open(error.message, 'Cerrar', { duration: 5000 });
      });
  }

  showLogin() {
    this.router.navigate(['/login']);
  }
}
