import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BlockUIModule } from 'ng-block-ui';
import { MomentModule } from 'ngx-moment';
import { environment } from '../environments/environment';
import { AngularMaterialModule } from './angular-material.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminComponent } from './components/admin/admin.component';
import { ChampionAndPichichiComponent } from './components/champion-and-pichichi/champion-and-pichichi.component';
import { FinalResultComponent } from './components/final-result/final-result.component';
import { GroupsComponent } from './components/groups/groups.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { MatchesComponent } from './components/matches/matches.component';
import { PollComponent } from './components/poll/poll.component';
import { ProfileComponent } from './components/profile/profile.component';
import { PromptModalComponent } from './components/prompt-modal/prompt-modal.component';
import { RestoreComponent } from './components/restore/restore.component';
import { RulesComponent } from './components/rules/rules.component';
import { AvatarComponent } from './components/shared/avatar/avatar.component';
import { ConfirmModalComponent } from './components/shared/confirm-modal/confirm-modal.component';
import { MatchesListComponent } from './components/shared/matches-list/matches-list.component';
import { SignupComponent } from './components/signup/signup.component';
import { StandingsComponent } from './components/standings/standings.component';
import { UsersComponent } from './components/users/users.component';
import { BackgroundUrlPipe } from './pipes/background-url.pipe';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    StandingsComponent,
    HomeComponent,
    AdminComponent,
    MatchesComponent,
    UsersComponent,
    GroupsComponent,
    PromptModalComponent,
    MatchesListComponent,
    ProfileComponent,
    PollComponent,
    RulesComponent,
    ChampionAndPichichiComponent,
    BackgroundUrlPipe,
    AvatarComponent,
    RestoreComponent,
    FinalResultComponent,
    ConfirmModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    AngularMaterialModule,
    BlockUIModule.forRoot(),
    MomentModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
