import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class StandingsService {
  constructor(private apiService: ApiService) {}

  getAll() {
    return this.apiService.request(`/standings`, 'GET');
  }

  getUser(userId: string) {
    return this.apiService.request(`/standings/user/${userId}`, 'GET');
  }

  getGroup(userId: string) {
    return this.apiService.request(`/standings/group/${userId}`, 'GET');
  }
}
