import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MatchesService } from '../../services/matches.service';
import { SidenavService } from '../../services/sidenav.service';
import { MatchesListComponent } from '../shared/matches-list/matches-list.component';

@Component({
  selector: 'app-matches',
  templateUrl: './matches.component.html',
  styleUrls: ['./matches.component.scss'],
})
export class MatchesComponent implements OnInit {
  @ViewChild(MatchesListComponent, { static: true })
  matchesListComponent: MatchesListComponent;
  @BlockUI() blockUI: NgBlockUI;

  sidenav: MatSidenav;
  matches: any[];
  rounds: any[];
  roundMatches: any[];
  roundSelected: string = 'GROUP_STAGE';

  constructor(
    private sidenavService: SidenavService,
    private matchesService: MatchesService,
    private snackbar: MatSnackBar
  ) {
    this.sidenav = this.sidenavService.getSidenav();
  }

  ngOnInit() {}

  toggleMenu() {
    this.sidenavService.toggle();
  }

  save() {
    this.blockUI.start();

    this.matchesService
      .updateResults(this.matchesListComponent.getResults())
      .then(() => {
        this.blockUI.stop();
        this.snackbar.open(
          'Los resultados se han actualizado correctamente.',
          'Cerrar',
          {
            duration: 5000,
          }
        );
      })
      .catch((error) => {
        this.blockUI.stop();
        this.snackbar.open(error.message, 'Cerrar', { duration: 5000 });
      });
  }
}
