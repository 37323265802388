import { Component, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FinalResultService } from '../../services/final-result.service';
import { SidenavService } from '../../services/sidenav.service';
import { TeamsService } from '../../services/teams.service';

@Component({
  selector: 'app-final-result',
  templateUrl: './final-result.component.html',
  styleUrls: ['./final-result.component.scss'],
})
export class FinalResultComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  sidenav: MatSidenav;
  finalResult: any;
  teams: any[];

  constructor(
    private sidenavService: SidenavService,
    private snackbar: MatSnackBar,
    private teamsService: TeamsService,
    private finalResultService: FinalResultService
  ) {
    this.sidenav = this.sidenavService.getSidenav();
  }

  async ngOnInit() {
    this.teams = await this.teamsService.getList();
    this.finalResult = await this.finalResultService.get();
  }

  toggleMenu() {
    this.sidenavService.toggle();
  }

  update() {
    this.blockUI.start();
    this.finalResultService
      .update(this.finalResult)
      .then(() => {
        this.blockUI.stop();
        this.snackbar.open(
          'Los datos se han actualizado correctamente.',
          'Cerrar',
          { duration: 3000 }
        );
      })
      .catch((error) => {
        this.blockUI.stop();
        this.snackbar.open(error.message, 'Cerrar', { duration: 3000 });
      });
  }
}
