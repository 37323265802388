<div class="signup-wrapper">
  <mat-card class="form">
    <mat-card-header>
      <mat-card-title>Registro</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-form-field>
        <input matInput placeholder="Email" [(ngModel)]="email" />
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Nombre y Apellidos" [(ngModel)]="name" />
      </mat-form-field>
      <mat-form-field>
        <input
          type="password"
          matInput
          placeholder="Contraseña"
          [(ngModel)]="password"
        />
      </mat-form-field>
      <mat-form-field>
        <input
          type="password"
          matInput
          placeholder="Confirmación Contraseña"
          [(ngModel)]="confirmPassword"
        />
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Código" [(ngModel)]="code" />
      </mat-form-field>
    </mat-card-content>
    <button mat-raised-button color="primary" (click)="signup()">Entrar</button>
    <button mat-button (click)="showLogin()" color="accent">
      Ya estoy registrado
    </button>
  </mat-card>
</div>

<block-ui></block-ui>
