import { Component, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { SidenavService } from '../../services/sidenav.service';

@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.scss'],
})
export class RulesComponent implements OnInit {
  sidenav: MatSidenav;

  constructor(private sidenavService: SidenavService) {
    this.sidenav = this.sidenavService.getSidenav();
  }

  ngOnInit(): void {}

  toggleMenu() {
    this.sidenavService.toggle();
  }
}
