<div class="content">
  <mat-chip-list
    aria-label="Round selection"
    [selectable]="true"
    [multiple]="false"
  >
    <mat-chip
      *ngFor="let round of rounds"
      (click)="selectRound(round)"
      [selected]="round.name === roundSelected"
      >{{ round.title }}</mat-chip
    >
  </mat-chip-list>

  <ng-container *ngIf="roundMatches && roundMatches.length > 0">
    <mat-card
      *ngFor="let match of roundMatches; let i = index"
      [class.failed]="match.points === 0"
      [class.success]="match.points > 0"
      [class.joker]="match.useJoker"
    >
      <!-- Match score -->
      <div class="match-score">
        <!-- Home -->
        <div class="match-team-wrapper homeTeam">
          <div class="team-logo">
            <img src="{{ match.homeTeam.flag }}" />
          </div>
          <div class="team-name">{{ match.homeTeam.name }}</div>
          <div class="score-wrapper">
            <input
              #input
              type="tel"
              maxlength="1"
              [(ngModel)]="match.homeGoals"
              [disabled]="
                !isAdmin &&
                mode !== 'results' &&
                match.roundIniTimestamp < currentTimestamp
              "
              *ngIf="
                isMe || (!isMe && match.roundIniTimestamp < currentTimestamp)
              "
            />
            <mat-checkbox
              [(ngModel)]="match.homeTeam.checked"
              [checked]="match.homeTeam.checked"
              (change)="penaltiesChange(match, 1)"
              *ngIf="
                match.round !== 'GROUP_STAGE' &&
                match.homeGoals !== null &&
                match.awayGoals !== null &&
                match.homeGoals === match.awayGoals
              "
            ></mat-checkbox>
            <span *ngIf="roundResults && mode === 'poll' && roundResults[i]">{{
              roundResults[i].homeGoals
            }}</span>
          </div>
        </div>

        <!-- Away -->
        <div class="match-team-wrapper awayTeam">
          <div class="team-logo">
            <img src="{{ match.awayTeam.flag }}" />
          </div>
          <div class="team-name">{{ match.awayTeam.name }}</div>
          <div class="score-wrapper">
            <input
              #input
              maxlength="1"
              type="tel"
              [(ngModel)]="match.awayGoals"
              [(ngModel)]="match.awayGoals"
              [disabled]="
                !isAdmin &&
                mode !== 'results' &&
                match.roundIniTimestamp < currentTimestamp
              "
              *ngIf="
                isMe || (!isMe && match.roundIniTimestamp < currentTimestamp)
              "
            />
            <mat-checkbox
              [(ngModel)]="match.awayTeam.checked"
              [checked]="match.awayTeam.checked"
              (change)="penaltiesChange(match, 2)"
              *ngIf="
                match.round !== 'GROUP_STAGE' &&
                match.homeGoals !== null &&
                match.awayGoals !== null &&
                match.homeGoals === match.awayGoals
              "
            ></mat-checkbox>
            <span *ngIf="roundResults && mode === 'poll' && roundResults[i]">{{
              roundResults[i].awayGoals
            }}</span>
          </div>
        </div>
      </div>

      <!-- Match info -->
      <div class="match-info">
        <div class="match-date">
          {{ match.date | amDateFormat : "LLLL" }}
        </div>
      </div>

      <!-- Match options -->
      <div
        *ngIf="isAdmin || (!isAdmin && isMe && mode !== 'results')"
        class="match-team-options"
      >
        <div (click)="toggleMatchOptions(match)">
          <mat-icon [class.show]="match.showOptions">arrow_downward</mat-icon>
        </div>

        <div
          [class.show]="match.showOptions"
          class="match-team-options-content"
        >
          <!-- JOKER -->
          <mat-list-item *ngIf="match.showOptions">
            <mat-label>¿Usar Joker?</mat-label>
            <mat-slide-toggle
              checked="match.useJoker"
              [(ngModel)]="match.useJoker"
              [disabled]="
                !isAdmin &&
                mode !== 'results' &&
                match.roundIniTimestamp < currentTimestamp
              "
            ></mat-slide-toggle>
          </mat-list-item>

          <div
            class="match-stats"
            *ngIf="
              match.showOptions && match.roundIniTimestamp < currentTimestamp
            "
          >
            <div class="stat-wrapper">
              <mat-chip>{{ match.stats?.homeWin }}</mat-chip>
              <span>1</span>
            </div>
            <div class="stat-wrapper">
              <mat-chip>{{ match.stats?.draw }}</mat-chip>
              <span>X</span>
            </div>
            <div class="stat-wrapper">
              <mat-chip>{{ match.stats?.awayWin }}</mat-chip>
              <span>2</span>
            </div>
            <div class="stat-wrapper">
              <mat-chip>{{ match.stats?.jokers }}</mat-chip>
              <img src="/assets/imgs/joker-on.svg" />
            </div>
          </div>
        </div>
      </div>
    </mat-card>
  </ng-container>
  <ng-container *ngIf="!roundMatches || roundMatches.length === 0">
    <div class="empty-placeholder"><img src="/assets/imgs/empty.svg" /></div>
  </ng-container>
</div>
