import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AmplitudeService } from '../../services/amplitude.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-restore',
  templateUrl: './restore.component.html',
  styleUrls: ['./restore.component.scss'],
})
export class RestoreComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  username: string;
  password: string;
  confirmPassword: string;
  code: string;

  constructor(
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private router: Router,
    private amplitudeService: AmplitudeService
  ) {
    this.code = this.route.snapshot.params['token'];
  }

  ngOnInit(): void {}

  restore() {
    if (!this.password || !this.username || !this.confirmPassword) {
      this.snackBar.open('Por favor, rellena todos los campos.', 'Cerrar', {
        duration: 3000,
      });
      return;
    }

    if (this.password !== this.confirmPassword) {
      this.snackBar.open(
        'La contraseña y su confirmación no coinciden.',
        'Cerrar',
        { duration: 3000 }
      );
      return;
    }

    this.blockUI.start();

    this.authService
      .restorePassword(
        this.username.trim().toLowerCase(),
        this.password,
        this.code
      )
      .then(() => {
        this.blockUI.stop();
        this.snackBar.open(
          'Has cambiado tu contraseña correctamente',
          'Cerrar',
          {
            duration: 5000,
          }
        );
        this.router.navigate(['/login']);
        this.amplitudeService.logEvent('RememberSuccess', {
          result: 'success',
        });
      })
      .catch((error) => {
        this.blockUI.stop();
        this.snackBar.open(error.message, 'Cerrar', { duration: 5000 });
      });
  }

  reset() {
    if (!this.username) {
      this.snackBar.open('Por favor, rellena todos los campos.', 'Cerrar', {
        duration: 3000,
      });
      return;
    }

    this.blockUI.start();

    this.authService
      .resetPassword(this.username.trim().toLowerCase())
      .then(() => {
        this.blockUI.stop();
        this.snackBar.open('Revisa tu correo electrónico', 'Cerrar', {
          duration: 5000,
        });
        this.amplitudeService.logEvent('RememberRequested', {
          result: 'success',
        });
      })
      .catch((error) => {
        this.blockUI.stop();
        this.snackBar.open(error.message, 'Cerrar', { duration: 5000 });
      });
  }
}
