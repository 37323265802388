import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class TeamsService {
  teams: any[];

  constructor(private apiService: ApiService) {}

  getList(): Promise<any[]> {
    return new Promise((resolve) => {
      if (!this.teams) {
        this.apiService.request('/teams', 'GET').then((teams) => {
          this.teams = teams;
          resolve(teams);
        });
      } else {
        resolve(this.teams);
      }
    });
  }
}
