import { Component, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { SidenavService } from '../../services/sidenav.service';
import { UsersService } from '../../services/users.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  sidenav: MatSidenav;
  user: any;
  userId: string;

  constructor(
    private sidenavService: SidenavService,
    private usersService: UsersService,
    private route: ActivatedRoute,
    private snackbar: MatSnackBar
  ) {
    this.sidenav = this.sidenavService.getSidenav();
    this.user = {};
  }

  async ngOnInit() {
    this.userId = this.route.snapshot.params['id'];
    this.user = await this.usersService.get(this.userId);
  }

  toggleMenu() {
    this.sidenavService.toggle();
  }

  update() {
    this.blockUI.start();
    this.usersService
      .update(this.user)
      .then(() => {
        this.blockUI.stop();
        this.snackbar.open(
          'El usuario se ha actualizado correctamente.',
          'Cerrar',
          { duration: 5000 }
        );
      })
      .catch((error) => {
        this.blockUI.stop();
        this.snackbar.open(error.message, 'Cerrar', { duration: 5000 });
      });
  }
}
