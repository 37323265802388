import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class MatchesService {
  rounds: any[];

  constructor(private apiService: ApiService) {}

  getList() {
    return this.apiService.request('/matches', 'GET');
  }

  getRounds(): Promise<any[]> {
    return new Promise((resolve) => {
      if (!this.rounds) {
        this.apiService.request('/rounds', 'GET').then((rounds) => {
          this.rounds = rounds;
          resolve(rounds);
        });
      } else {
        resolve(this.rounds);
      }
    });
  }

  updateResults(matches: any[]) {
    return this.apiService.request('/matches/results', 'POST', matches);
  }
}
