<mat-toolbar class="main-header">
  <button
    mat-icon-button
    *ngIf="sidenav.mode === 'over'"
    (click)="toggleMenu()"
  >
    <mat-icon *ngIf="!sidenav.opened"> menu </mat-icon>
    <mat-icon *ngIf="sidenav.opened"> close </mat-icon>
  </button>
  <span>ADMIN: Campeón y Pichichi</span>
</mat-toolbar>

<div class="content-padding form">
  <mat-form-field>
    <mat-label>Campeón</mat-label>
    <mat-select [(ngModel)]="finalResult.teamId">
      <mat-option
        *ngFor="let team of teams"
        class="teams-select"
        [value]="team._id"
        ><img src="{{ team.flag }}" /> {{ team.name }}</mat-option
      >
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Pichichi</mat-label>
    <input matInput type="text" [(ngModel)]="finalResult.pichichi" required />
  </mat-form-field>
</div>

<mat-toolbar class="footer">
  <button mat-raised-button color="primary" (click)="update()">Guardar</button>
</mat-toolbar>

<block-ui></block-ui>
