import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class FinalResultService {
  constructor(private apiService: ApiService) {}

  update(finalResult: any) {
    return this.apiService.request('/final-result', 'POST', finalResult);
  }

  get() {
    return this.apiService.request('/final-result', 'GET');
  }
}
