import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { MatchesService } from '../../../services/matches.service';
import { PollsService } from '../../../services/polls.service';
import { UtilsService } from '../../../services/utils.service';

@Component({
  selector: 'app-matches-list',
  templateUrl: './matches-list.component.html',
  styleUrls: ['./matches-list.component.scss'],
})
export class MatchesListComponent implements OnInit {
  @Input() mode: string;
  @Input() user: any;
  @Output() roundChanged: EventEmitter<any> = new EventEmitter<any>();
  // @ViewChildren('input') inputs: QueryList<any>;

  matches: any[];
  rounds: any[];
  roundMatches: any[];
  roundSelected: string = 'GROUP_STAGE';
  currentTimestamp: number;
  isAdmin: boolean;
  dataLoaded$: EventEmitter<any>;
  roundResults: any;
  isMe: boolean;

  private polls: any[];

  constructor(
    private matchesService: MatchesService,
    private authService: AuthService,
    private pollsService: PollsService,
    private utilsService: UtilsService
  ) {
    this.currentTimestamp = new Date().getTime() / 1000;
    const loggedUser = this.authService.getUser();

    if (!this.user) {
      this.user = loggedUser;
    }

    this.isMe = this.user._id === loggedUser._id;
    this.isAdmin = this.authService.isAdmin();
  }

  // initInputs() {
  //   this.inputs.forEach((input, index, inputsArray) => {
  //     input.nativeElement.addEventListener('input', () => {
  //       if (
  //         input.nativeElement.value.length === input.nativeElement.maxLength
  //       ) {
  //         const nextInput = inputsArray[index + 1];

  //         if (nextInput) {
  //           nextInput.nativeElement.focus();
  //           this.scrollToInput(nextInput.nativeElement, 100); // Adjust 20 pixels as margin
  //         }
  //       }
  //     });

  //     input.nativeElement.addEventListener('focus', () => {
  //       this.scrollToInput(input.nativeElement, 100);
  //     });
  //   });
  // }

  // private scrollToInput(element: HTMLElement, margin: number): void {
  //   element.scrollIntoView({
  //     behavior: 'smooth',
  //     block: 'start',
  //   });

  //   // Adjust the scroll position to include the desired margin
  //   setTimeout(() => {
  //     const scrollContainer = this.getScrollContainer(element);
  //     if (scrollContainer) {
  //       scrollContainer.scrollTop -= margin;
  //     }
  //   }, 300); // Adjust timeout as necessary to wait for the scroll to complete
  // }

  // private getScrollContainer(element: HTMLElement): HTMLElement | null {
  //   let parent = element.parentElement;
  //   while (parent) {
  //     const overflowY = window.getComputedStyle(parent).overflowY;
  //     if (overflowY === 'auto' || overflowY === 'scroll') {
  //       return parent;
  //     }
  //     parent = parent.parentElement;
  //   }
  //   return null;
  // }

  async ngOnInit() {
    this.rounds = await this.matchesService.getRounds();
    const results = await this.matchesService.getList();
    this.roundResults = results.filter(
      (match: any) => match.round === this.roundSelected
    );
    await this.loadMatches();
    this.roundMatches = this.matches.filter(
      (match) => match.round === this.roundSelected
    );

    await this.loadPolls();
    // this.initInputs();
  }

  async selectRound(round: any) {
    this.roundSelected = round.name;
    this.roundMatches = this.matches.filter(
      (match) => match.round === this.roundSelected
    );
    this.roundChanged.emit(this.roundSelected);
    const results = await this.matchesService.getList();
    this.roundResults = results.filter(
      (match: any) => match.round === this.roundSelected
    );
  }

  private async loadMatches() {
    let matches = await this.matchesService.getList();
    matches = this.addUIProperties(matches);

    if (this.mode === 'poll') {
      matches = matches.map((match: any) => {
        match.homeGoals = null;
        match.awayGoals = null;
        match.penaltiesWinner = null;

        return match;
      });
    } else {
      matches = matches.map((match: any) => {
        if (match.penaltiesWinner) {
          if (match.penaltiesWinner._id === match.homeTeam._id) {
            match.homeTeam.checked = true;
            match.awayTeam.checked = false;
          } else if (match.penaltiesWinner._id === match.awayTeam._id) {
            match.homeTeam.checked = false;
            match.awayTeam.checked = true;
          }
        }

        return match;
      });
    }

    this.matches = matches;
    // this.initInputs();
  }

  private async loadPolls() {
    this.polls = await this.pollsService.getList();
    this.matches = this.utilsService.addUIPollsPropertiesToMatches(
      this.matches,
      this.polls,
      this.user
    );
  }

  private addUIProperties(matches: any[]) {
    matches.map((match: any) => {
      match.roundIniTimestamp = this.rounds.find(
        (round) => round.name === match.round
      ).iniTimestamp;
      match.showOptions = false;

      return match;
    });

    return matches;
  }

  penaltiesChange(match: any, team: number) {
    if (match.homeTeam.checked && match.awayTeam.checked) {
      if (team === 1) {
        match.awayTeam.checked = false;
      } else {
        match.homeTeam.checked = false;
      }
    }
  }

  toggleMatchOptions(match: any) {
    match.showOptions = !match.showOptions;
  }

  getResults() {
    return this.matches.map((match: any) => {
      if (match.round !== 'GROUP_STAGE') {
        if (match.homeTeam.checked) {
          match.penaltiesWinner = match.homeTeam._id;
        } else if (match.awayTeam.checked) {
          match.penaltiesWinner = match.awayTeam._id;
        } else {
          match.penaltiesWinner = null;
        }
      } else {
        match.penaltiesWinner = null;
      }

      match.match = { _id: match._id };

      delete match.homeTeam['checked'];
      delete match.awayTeam['checked'];
      // delete match._id;

      return match;
    });
  }
}
