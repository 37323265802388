<mat-toolbar class="main-header">
  <button
    mat-icon-button
    *ngIf="sidenav.mode === 'over'"
    (click)="toggleMenu()"
  >
    <mat-icon *ngIf="!sidenav.opened"> menu </mat-icon>
    <mat-icon *ngIf="sidenav.opened"> close </mat-icon>
  </button>
  <span>{{ user?.name }}</span>
</mat-toolbar>

<div class="content-padding form">
  <mat-form-field>
    <mat-label>Nombre</mat-label>
    <input matInput type="text" [(ngModel)]="user.name" required />
  </mat-form-field>

  <mat-form-field>
    <mat-label>Email</mat-label>
    <input matInput type="email" [(ngModel)]="user.email" required />
  </mat-form-field>

  <mat-form-field>
    <mat-label>Password</mat-label>
    <input matInput type="password" [(ngModel)]="user.password" required />
  </mat-form-field>
</div>

<mat-toolbar class="footer">
  <button mat-raised-button color="primary" (click)="update()">Guardar</button>
</mat-toolbar>

<block-ui></block-ui>
