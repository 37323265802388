import { Component, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AuthService } from '../../services/auth.service';
import { MatchesService } from '../../services/matches.service';
import { SidenavService } from '../../services/sidenav.service';
import { TeamsService } from '../../services/teams.service';
import { UsersService } from '../../services/users.service';

@Component({
  selector: 'app-champion-and-pichichi',
  templateUrl: './champion-and-pichichi.component.html',
  styleUrls: ['./champion-and-pichichi.component.scss'],
})
export class ChampionAndPichichiComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  sidenav: MatSidenav;
  user: any;
  userId: string;
  loggedUser: any;
  isAdmin: boolean;
  currentTimestamp: number;
  firstRoundIniTimestamp: number;
  teams: any[];
  isMe: boolean = false;

  private rounds: any[];

  constructor(
    private sidenavService: SidenavService,
    private usersService: UsersService,
    private route: ActivatedRoute,
    private snackbar: MatSnackBar,
    private authService: AuthService,
    private matchesService: MatchesService,
    private teamsService: TeamsService
  ) {
    this.sidenav = this.sidenavService.getSidenav();
    this.loggedUser = this.authService.getUser();
    this.currentTimestamp = new Date().getTime() / 1000;
  }

  async ngOnInit() {
    this.rounds = await this.matchesService.getRounds();
    this.teams = await this.teamsService.getList();
    this.firstRoundIniTimestamp = this.rounds[0].iniTimestamp;
    this.userId = this.route.snapshot.params['id'];
    this.isAdmin = this.authService.isAdmin();

    if (this.userId) {
      if (
        this.userId !== this.loggedUser._id &&
        this.currentTimestamp < this.firstRoundIniTimestamp
      ) {
        this.snackbar.open(
          'No puedes ver los resultados de los demás hasta que no se haya iniciado la ronda',
          'Cerrar',
          { duration: 3000 }
        );

        return;
      }

      this.user = await this.usersService.get(this.userId);
    } else {
      this.user = this.loggedUser;
    }

    if (!this.user.jokers) {
      this.user.jokers = {
        champion: false,
        pichichi: false,
        matches: [],
      };
    }

    if (!this.user.team?._id) {
      this.user.team = { _id: null };
    }

    if (!this.user.pichichi) {
      this.user.pichichi = null;
    }

    this.isMe = this.user._id === this.loggedUser._id;
  }

  toggleMenu() {
    this.sidenavService.toggle();
  }

  update() {
    this.blockUI.start();
    this.usersService
      .update(this.user)
      .then(() => {
        this.blockUI.stop();
        this.snackbar.open(
          'Los datos se han actualizado correctamente.',
          'Cerrar',
          { duration: 3000 }
        );
      })
      .catch((error) => {
        this.blockUI.stop();
        this.snackbar.open(error.message, 'Cerrar', { duration: 3000 });
      });
  }
}
