import { Component, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { SidenavService } from '../../services/sidenav.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
  sidenav: MatSidenav;

  constructor(private sidenavService: SidenavService, private router: Router) {
    this.sidenav = this.sidenavService.getSidenav();
  }

  ngOnInit(): void {}

  toggleMenu() {
    this.sidenavService.toggle();
  }

  showResults() {}

  showFinalResult() {
    this.router.navigate(['/admin/final-result']);
  }

  showMatches() {
    this.router.navigate(['/admin/matches']);
  }

  showUsers() {
    this.router.navigate(['/admin/users']);
  }

  showGroups() {
    this.router.navigate(['/admin/groups']);
  }
}
