import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-prompt-modal',
  templateUrl: './prompt-modal.component.html',
  styleUrls: ['./prompt-modal.component.scss'],
})
export class PromptModalComponent implements OnInit {
  public title: string;
  public label: string;
  public promptValue: any;

  constructor(
    public dialog: MatDialogRef<PromptModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.title = this.data.title;
    this.label = this.data.label;
  }

  close() {
    this.dialog.close(false);
  }

  accept() {
    this.dialog.close(this.promptValue);
  }
}
