import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AuthService } from '../../services/auth.service';
import { FinalResultService } from '../../services/final-result.service';
import { GroupsService } from '../../services/groups.service';
import { LocalService } from '../../services/local.service';
import { MatchesService } from '../../services/matches.service';
import { PollsService } from '../../services/polls.service';
import { SidenavService } from '../../services/sidenav.service';
import { StandingsService } from '../../services/standings.service';
import { UtilsService } from '../../services/utils.service';
import { ConfirmModalComponent } from '../shared/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-standings',
  templateUrl: './standings.component.html',
  styleUrls: ['./standings.component.scss'],
})
export class StandingsComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  sidenav: MatSidenav;
  standings: Array<any>;
  groups: Array<any>;
  groupSelected: string;
  loggedUser: any;
  firstRoundIniTimestamp: number;
  currentTimestamp: number;
  liveMatches: Array<any>;

  private finishedMatches: Array<any>;
  private polls: Array<any>;
  private matches: Array<any>;
  private finalResult: any;
  private allStandings: any;

  constructor(
    private sidenavService: SidenavService,
    private authService: AuthService,
    private localService: LocalService,
    private standingsService: StandingsService,
    private groupsService: GroupsService,
    private matchesService: MatchesService,
    private router: Router,
    private pollsService: PollsService,
    private utilsService: UtilsService,
    private finalResultService: FinalResultService,
    private dialog: MatDialog
  ) {
    this.sidenav = this.sidenavService.getSidenav();
    this.standings = [];
    this.loggedUser = this.authService.getUser();
  }

  async ngOnInit() {
    this.blockUI.start();
    this.groups = await this.groupsService.getList();
    this.matches = await this.matchesService.getList();
    this.groupSelected = this.localService.getData('stgrsel') || 'general';
    const rounds = await this.matchesService.getRounds();
    this.firstRoundIniTimestamp = rounds[0].iniTimestamp;
    this.currentTimestamp = new Date().getTime() / 1000;
    this.finalResult = await this.finalResultService.get();
    this.allStandings = await this.standingsService.getAll();
    await this.getLiveMatches();
    this.showStandings();
    setInterval(this.getLiveMatches.bind(this), 60000);
    this.blockUI.stop();
  }

  toggleMenu() {
    this.sidenavService.toggle();
  }

  selectUser(user: any) {
    this.router.navigate([`/home/${user._id}`]);
  }

  selectGroup(groupId: string) {
    this.groupSelected = groupId;
    this.localService.saveData('stgrsel', this.groupSelected);
    this.showStandings();
  }

  async reload() {
    this.blockUI.start();
    this.matches = await this.matchesService.getList();
    this.allStandings = await this.standingsService.getAll();
    await this.getLiveMatches();
    this.blockUI.stop();
    this.showStandings();
  }

  showUsers(users: any[]) {
    let message = '<ul>';
    users.forEach((user) => {
      message += `<li>${user.name}</li>`;
    });
    message += '</ul>';

    this.dialog.open(ConfirmModalComponent, {
      data: {
        title: 'Participantes',
        message: message,
      },
    });
  }

  private async showStandings() {
    const standings = { ...this.allStandings };
    let evo: any[] = [];
    let currentStandings: any[] = [];

    if (this.groupSelected === 'general') {
      evo = standings.standingsEvolution;
      currentStandings = standings.currentStandings;
    } else {
      evo = standings.standingsEvolution.map((element: any[]) => {
        return element.filter((item: any) =>
          item.user.groups?.includes(this.groupSelected)
        );
      });
      currentStandings = standings.currentStandings.filter((item: any) =>
        item.user.groups?.includes(this.groupSelected)
      );
    }

    // this.standings = standings.currentStandings;

    // const userClassifications = standings.standingsEvolution.filter(
    //   (element: any) => element.user._id === this.loggedUser._id
    // );
    this.standings = currentStandings.map((standing: any) => {
      if (evo.length < 2) {
        standing.posChange = {
          number: '',
          change: 'equal',
        };
      } else {
        const userPreviousPos = evo[evo.length - 2].findIndex(
          (item: any) => item.user._id === standing.user._id
        );
        const userCurrentPos = evo[evo.length - 1].findIndex(
          (item: any) => item.user._id === standing.user._id
        );
        let changedPos = evo.length > 1 ? userCurrentPos - userPreviousPos : 0;

        standing.posChange = {
          number: changedPos == 0 ? '' : Math.abs(changedPos),
          change: changedPos == 0 ? 'equal' : changedPos > 0 ? 'down' : 'up',
        };
      }

      standing.jokersConsumed = this.finishedMatches.reduce((acc, match) => {
        if (standing.user.jokers.matches.indexOf(match._id) !== -1) {
          if (match.round === 'GROUP_STAGE') {
            return acc + 1;
          } else if (
            match.round === 'LAST_16' ||
            match.round === 'QUARTER_FINALS'
          ) {
            return acc + 2;
          } else {
            return acc + 3;
          }
        } else {
          return acc + 0;
        }
      }, 0);

      if (this.finalResult.pichichi && this.finalResult.teamId) {
        if (standing.user.jokers.champion === true) {
          standing.jokersConsumed += 4;
        }

        if (standing.user.jokers.pichichi === true) {
          standing.jokersConsumed += 4;
        }
      }

      standing.currentResults = this.liveMatches?.map((match) => {
        const userPoll = this.polls.find(
          (poll: any) => poll.user?._id === standing.user._id
        );

        if (!userPoll) {
          return { homeGoals: 0, awayGoals: 0, useJoker: false };
        }

        const pollMatch = userPoll.results.find(
          (result: any) => result.match._id === match._id
        );
        return {
          homeGoals: pollMatch?.homeGoals,
          awayGoals: pollMatch?.awayGoals,
          useJoker: userPoll.user.jokers?.matches?.indexOf(match._id) !== -1,
        };
      });

      return standing;
    });
  }

  private async getLiveMatches() {
    this.finishedMatches =
      this.matches.filter((match: any) => match.status === 'FINISHED') ?? [];
    const liveMatches = this.matches.filter((match: any) => {
      return ['IN_PLAY', 'LIVE'].includes(match.status);
    });

    if (liveMatches.length === 0) {
      return;
    }

    this.polls = await this.pollsService.getList();
    this.liveMatches = this.utilsService.addUIPollsPropertiesToMatches(
      liveMatches,
      this.polls,
      this.loggedUser
    );
  }
}
