import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { GroupsService } from '../../services/groups.service';
import { SidenavService } from '../../services/sidenav.service';
import { UsersService } from '../../services/users.service';
import { PromptModalComponent } from '../prompt-modal/prompt-modal.component';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss'],
})
export class GroupsComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  sidenav: MatSidenav;
  groups: any[];
  usersByGroup: any = { NOGROUP: [] };

  private users: any[];

  constructor(
    private sidenavService: SidenavService,
    private groupsService: GroupsService,
    private usersService: UsersService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar
  ) {
    this.sidenav = this.sidenavService.getSidenav();
  }

  async ngOnInit() {
    this.groups = await this.groupsService.getList();
    this.users = await this.usersService.getList();
    this.processUsers();
  }

  toggleMenu() {
    this.sidenavService.toggle();
  }

  createGroup() {
    this.dialog
      .open(PromptModalComponent, {
        data: {
          title: 'Crear Grupo',
          label: 'Nombre del grupo',
        },
      })
      .afterClosed()
      .subscribe((value) => {
        if (!value) {
          return;
        }

        this.blockUI.start();
        this.groupsService
          .create({ name: value })
          .then((newGroup: any) => {
            this.groups.unshift(newGroup);
            this.blockUI.stop();
            this.snackbar.open(
              'El grupo se ha creado correctamente.',
              'Cerrar',
              {
                duration: 5000,
              }
            );
          })
          .catch((error) => {
            this.blockUI.stop();
            this.snackbar.open(error.message, 'Cerrar', { duration: 5000 });
          });
      });
  }

  changeGroup(user: any, groupId?: string) {
    if (!user.groups) {
      user.groups = [];
    }

    if (!groupId) {
      user.groups = [];
    } else {
      user.groups.push(groupId);
    }

    this.blockUI.start();
    this.usersService
      .update(user)
      .then(() => {
        this.blockUI.stop();
        this.processUsers();
      })
      .catch((error) => {
        this.blockUI.stop();
        this.snackbar.open(error.message, 'Cerrar', { duration: 5000 });
      });
  }

  private processUsers() {
    this.usersByGroup = { NOGROUP: [] };
    this.users.forEach((user: any) => {
      if (!user.groups || user.groups.length === 0) {
        this.usersByGroup['NOGROUP'].push(user);
      } else {
        user.groups.forEach((groupId: string) => {
          const groupName = this.groups.find(
            (group) => group._id === groupId
          )?.name;

          if (!this.usersByGroup[groupName]) {
            this.usersByGroup[groupName] = [];
          }

          this.usersByGroup[groupName].push(user);
        });
      }
    });

    console.log(this.usersByGroup);
  }
}
