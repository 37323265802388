import { Identify, init, setUserId, track } from '@amplitude/analytics-browser';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AmplitudeService {
  constructor(private authService: AuthService) {}

  initializeAPI() {
    init(environment.Amplitude.APIKey);
  }

  setUser() {
    const user = this.authService.getUser();
    const identifyObj = new Identify();
    setUserId(user.email);
    identifyObj.set('name', user.name);
  }

  logEvent(name: string, properties = {}) {
    track(name, properties);
  }
}
