<mat-toolbar class="main-header">
  <button
    mat-icon-button
    *ngIf="sidenav.mode === 'over'"
    (click)="toggleMenu()"
  >
    <mat-icon *ngIf="!sidenav.opened"> menu </mat-icon>
    <mat-icon *ngIf="sidenav.opened"> close </mat-icon>
  </button>
  <span>Participantes</span>
  <span class="spacer"></span>
  <button mat-raised-button color="basic" (click)="sendPaymentReminders()">
    Recordatorios $
  </button>
</mat-toolbar>

<div class="content">
  <mat-list>
    <mat-list-item *ngFor="let user of users">
      <app-avatar
        matListAvatar
        [user]="user"
        (click)="editUser(user)"
      ></app-avatar>
      <h4 (click)="editUser(user)">{{ user.name }}</h4>
      <div class="spacer"></div>
      <button
        mat-mini-fab
        [class.success]="user.hasPaid"
        [class.danger]="!user.hasPaid"
        (click)="togglePayment(user)"
      >
        <mat-icon class="mat-24">attach_money</mat-icon>
      </button>
      <button mat-mini-fab (click)="delete(user)">
        <mat-icon class="mat-24">delete</mat-icon>
      </button>
    </mat-list-item>
  </mat-list>
</div>
