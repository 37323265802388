<mat-toolbar class="main-header">
  <button
    mat-icon-button
    *ngIf="sidenav.mode === 'over'"
    (click)="toggleMenu()"
  >
    <mat-icon *ngIf="!sidenav.opened"> menu </mat-icon>
    <mat-icon *ngIf="sidenav.opened"> close </mat-icon>
  </button>
  <span>Partidos</span>
</mat-toolbar>

<app-matches-list mode="results"></app-matches-list>

<mat-toolbar class="footer">
  <button mat-raised-button color="primary" (click)="save()">Guardar</button>
</mat-toolbar>

<block-ui></block-ui>
