<mat-toolbar class="main-header">
  <button
    mat-icon-button
    *ngIf="sidenav.mode === 'over'"
    (click)="toggleMenu()"
  >
    <mat-icon *ngIf="!sidenav.opened"> menu </mat-icon>
    <mat-icon *ngIf="sidenav.opened"> close </mat-icon>
  </button>
  <span>Administración</span>
</mat-toolbar>

<div class="content">
  <mat-list>
    <mat-list-item (click)="showMatches()">
      <mat-icon mat-list-icon>sports_soccer</mat-icon>
      Partidos
    </mat-list-item>

    <mat-list-item (click)="showFinalResult()">
      <mat-icon mat-list-icon>workspace_premium"</mat-icon>
      Campeón y Pichichi
    </mat-list-item>

    <mat-list-item (click)="showUsers()">
      <mat-icon mat-list-icon>people</mat-icon>
      Participantes
    </mat-list-item>

    <mat-list-item (click)="showGroups()">
      <mat-icon mat-list-icon>supervised_user_circle</mat-icon>
      Grupos
    </mat-list-item>
  </mat-list>
</div>
