<mat-toolbar class="main-header">
  <button
    mat-icon-button
    *ngIf="sidenav.mode === 'over'"
    (click)="toggleMenu()"
  >
    <mat-icon *ngIf="!sidenav.opened"> menu </mat-icon>
    <mat-icon *ngIf="sidenav.opened"> close </mat-icon>
  </button>
  <span>Reglas</span>
</mat-toolbar>

<div class="content">
  <h1>REGLAS DEL JUEGO.</h1>

  <p>
    El juego consiste en pronosticar <b>todos</b> los resultados de la Eurocopa.
    Quien más resultados acierte gana. Se puntúan dos tipos de aciertos. El
    primero y más importante es el pronóstico, es decir, acertar quien gana el
    partido o si hay empate. El segundo es acertar el resultado, es decir, el
    número de goles por equipo. Al principio del juego también deberás
    pronosticar el <b>Campeón</b> y el <b>Pichichi</b> de la Eurocopa.
  </p>

  <h2>Puntuaciones básicas</h2>
  <ul>
    <li>Pronóstico acertado (1X2): 3 puntos.</li>
    <li>Resultado exacto: 2 puntos extras.</li>
    <li>(Semifinales y Final) Pronóstico acertado (1X2): 6 puntos.</li>
    <li>(Semifinales y Final) Resultado exacto: 4 puntos extra.</li>
    <li>Campeón de la Eurocopa: 15 puntos.</li>
    <li>Pichichi de la Eurocopa: 10 puntos.</li>
  </ul>
  <p>
    <i
      >NOTA: especial atención a la puntuación doble de las semifinales y la
      final</i
    >
  </p>

  <h2>JOKERS</h2>
  <p>
    Los <b>JOKERs</b> hacen que la puntuación allí dónde lo juegues se
    <b>doble</b>. Los JOKERS se pueden jugar en cualquier partido.
  </p>
  <p>
    Se dispondrán de 5 JOKERS en total, pero según dónde los uses deberás gastar
    más o menos:
  </p>
  <ul>
    <li>
      Para hacer que un partido de <b>fase previa</b> tenga valor doble (6
      puntos por pronóstico + 4 por resultado exacto) se consume 1 JOKER.
    </li>
    <li>
      Para hacer que un partido de <b>octavos o cuartos</b> tenga valor doble (6
      puntos por pronóstico + 4 por resultado exacto) se consumen 2 JOKERS.
    </li>
    <li>
      Para hacer que un partido de
      <b>semifinales y final</b> valga doble (12 puntos por pronóstico + 8 por
      resultado exacto) se consumen 3 JOKERS.
    </li>
  </ul>
  <p>Cosas a tener en cuenta:</p>
  <ul>
    <li>
      Los JOKERS para los partidos se deberán colocar justo antes del inicio de
      cada ronda.
    </li>
  </ul>
  <p>
    De esta manera cada uno los usará con la estrategia que crea más adecuada,
    asumiendo más o menos riesgo.
  </p>
  <h3>Ejemplos:</h3>
  <ul>
    <li>JOKERS en 5 partidos de la fase de grupos (5).</li>
    <li>JOKERS en 3 partidos de la fase de grupos (3) y uno en cuartos (2).</li>
    <li>
      JOKER en 2 partidos de la fase de grupos (2) y uno en las semifinales (3).
    </li>
  </ul>

  <h2>Normas de la liguilla: (36 partidos)</h2>
  <p>
    En esta podrá haber 3 tipos de resultados, la victoria de un equipo, la del
    otro y el empate. No hay ni prórroga ni penaltis.
  </p>
  <p>
    Basta con que pongáis los resultados en el apartado de la App destinado para
    ello.
  </p>
  <p>
    Una vez finalizada la liguilla, el participante deberá ir rellenando los
    resultados de cada fase siguiente.
  </p>

  <h2>Normas de las eliminatorias: (16 partidos)</h2>
  <p>
    En las eliminatorias el resultado que prevalece es el que se produzca al
    finalizar los 90 minutos de juego reglamentarios más la prórroga. Si el
    participante opta por pronosticar un empate durante el partido (90 minutos
    reglamentarios + prórroga), este deberá indicar cuál de los dos equipos pasa
    la eliminatoria en la tanda de penaltis. En caso de pronosticar un empate,
    solo podrá optarse a los 5 puntos si se acierta el resultado y el pronóstico
    de la eliminatoria es decir, qué equipo gana la tanda de penaltis. A
    continuación se explican las diferentes posibilidades de puntuación en el
    caso de optar por el empate como resultado del tiempo de juego:
  </p>

  <h3>Ejemplos:</h3>
  <p>
    Eliminatoria. Francia-Alemania. Resultado al fin de los 90 minutos +
    prórroga: 3-3. Vencedor Francia en los penaltis. Situaciones:
  </p>
  <ol type="a">
    <li>
      Participante A: pronostica un 3-3 y da como vencedor de la eliminatoria a
      Francia: 5 puntos (3 del pronóstico y 2 del resultado)
    </li>
    <li>
      Participante B: pronostica un 3-3 pero da como vencedor a Alemania: 2
      puntos (0 del pronóstico y 2 del resultado)
    </li>
    <li>
      Participante C: pronostica un 3-2 a favor de Francia: 3 puntos (3 del
      pronóstico y 0 del resultado)
    </li>
    <li>
      Participante D: Pronostica un 1-1 y da como vencedor a Francia: 3 puntos
      (3 puntos del pronóstico y 0 del resultado)
    </li>
  </ol>

  <h2>Puntos extra:</h2>
  <p>
    Al inicio del juego el participante deberá pronosticar el vencedor de la
    Eurocopa. Dicho pronóstico en caso de cumplirse le otorgará al final del
    juego 15 puntos extra.
  </p>
  <p>
    Al inicio del juego el participante deberá pronosticar el máximo goleador de
    la Eurocopa. Dicho pronóstico en caso de cumplirse le otorgará al final del
    juego 10 puntos extra. En caso de que haya varios jugadores con el mismo
    número de goles, <b>todos contarán como máximo goleador</b>.
  </p>

  <h2>Desempate</h2>
  <p>Será primero el que:</p>
  <ol>
    <li>
      Haya acertado mayor número de resultados exactos en todo la Eurocopa.
    </li>
    <li>
      En caso de que persista el empate, será ganador el que haya acertado el
      ganador de la Eurocopa.
    </li>
    <li>Después el Pichichi.</li>
    <li>Luego mayor número de pronósticos 1 X 2 acertado.</li>
    <li>
      Y por último el mayor número de pronósticos 1 X 2 acertado en la fase de
      grupos.
    </li>
  </ol>
  <p>
    Este desempate es válido únicamente en caso de que haya empate por el primer
    puesto. En caso de empate en la segunda plaza se considerará a todos los que
    estén en esa posición como "medalla de plata" y no habrá tercer lugar de
    honor. En caso de empate en la tercera plaza también se considerará como
    "medalla de bronce" a todos los jugadores que estén empatados en esa
    posición.
  </p>
  <p>
    <b
      >Importante!!! En cada fase cada uno de los participantes deberá emitir
      sus pronósticos con antelación al inicio de la misma sin posibilidad de
      cambios una vez iniciada ésta. Cada vez que guardes tus resultados
      recibirás un email para que puedas comprobar que se están guardando
      correctamente.
    </b>
  </p>
</div>
