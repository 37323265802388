import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './components/admin/admin.component';
import { ChampionAndPichichiComponent } from './components/champion-and-pichichi/champion-and-pichichi.component';
import { FinalResultComponent } from './components/final-result/final-result.component';
import { GroupsComponent } from './components/groups/groups.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { MatchesComponent } from './components/matches/matches.component';
import { PollComponent } from './components/poll/poll.component';
import { ProfileComponent } from './components/profile/profile.component';
import { RestoreComponent } from './components/restore/restore.component';
import { RulesComponent } from './components/rules/rules.component';
import { SignupComponent } from './components/signup/signup.component';
import { StandingsComponent } from './components/standings/standings.component';
import { UsersComponent } from './components/users/users.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: StandingsComponent,
    canActivate: [AuthGuard],
  },

  { path: 'login', component: LoginComponent },
  { path: 'register', component: SignupComponent },
  { path: 'restore', component: RestoreComponent },
  { path: 'restore/:token', component: RestoreComponent },

  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'home/:id', component: HomeComponent, canActivate: [AuthGuard] },
  {
    path: 'standings',
    component: StandingsComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'poll',
    component: PollComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'poll/:id',
    component: PollComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'champion',
    component: ChampionAndPichichiComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'champion/:id',
    component: ChampionAndPichichiComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admin/matches',
    component: MatchesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admin/users',
    component: UsersComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admin/users/:id',
    component: ProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admin/groups',
    component: GroupsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admin/final-result',
    component: FinalResultComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'rules',
    component: RulesComponent,
    canActivate: [AuthGuard],
  },

  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      relativeLinkResolution: 'legacy',
      // enableTracing: true
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
