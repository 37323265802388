<h1 mat-dialog-title>{{ title }}</h1>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>{{ label }}</mat-label>
    <input type="text" matInput [(ngModel)]="promptValue" required />
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-stroked-button (click)="close()" color="primary">Cancelar</button>
  <button mat-raised-button (click)="accept()" color="primary">Aceptar</button>
</div>
