import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { LocalService } from './local.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public rootUrl: string;
  public token: string = '';

  constructor(private localService: LocalService, private http: HttpClient) {
    this.rootUrl = environment.APIEndpoint;
  }

  async init() {
    this.token = this.localService.getData('token');
  }

  request(path: string, method: string, params?: any): Promise<any> {
    if (method === 'GET') {
      return this.doGetRequest(path, params);
    } else if (method === 'DELETE') {
      return this.doDeleteRequest(path);
    }

    return this.doPostRequest(path, params);
  }

  private doGetRequest(path: string, params: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        let headers = new HttpHeaders();
        headers = headers.set('x-access-token', this.token);

        this.http
          .get(`${this.rootUrl}${path}`, { headers })
          .subscribe((result) => {
            if (this.isError(result)) {
              reject(result);
            } else {
              resolve(result);
            }
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  private doDeleteRequest(path: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        let headers = new HttpHeaders();
        headers = headers.set('x-access-token', this.token);

        this.http
          .delete(`${this.rootUrl}${path}`, { headers })
          .subscribe((result) => {
            if (this.isError(result)) {
              reject(result);
            } else {
              resolve(result);
            }
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  private doPostRequest(path: string, params: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        let headers = new HttpHeaders();

        if (this.token) {
          headers = headers.set('x-access-token', this.token);
        }

        this.http
          .post(`${this.rootUrl}${path}`, params, { headers })
          .subscribe((result) => {
            if (this.isError(result)) {
              reject(result);
            } else {
              resolve(result);
            }
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  uploadFile(path: string, file: File) {
    return new Promise(async (resolve, reject) => {
      const formData: FormData = new FormData();
      formData.append('uploadFile', file, file.name);

      let headers = new HttpHeaders();
      headers = headers.set('x-access-token', this.token);
      // headers = headers.set('Content-Type', 'multipart/form-data');
      headers = headers.set('Accept', 'application/json');

      try {
        this.http
          .post(`${this.rootUrl}${path}`, formData, { headers })
          .subscribe((result) => {
            if (this.isError(result)) {
              reject(result);
            } else {
              resolve(result);
            }
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  private isError(response: any) {
    return response && typeof response === 'object' && 'error' in response;
  }

  setToken(token: string) {
    this.token = token;
  }
}
