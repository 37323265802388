<div
  *ngIf="user?.avatar"
  class="avatar"
  [ngStyle]="{ 'background-image': user?.avatar | asUrl }"
></div>
<div
  *ngIf="!user?.avatar"
  class="avatar"
  [ngStyle]="{ 'background-image': 'assets/imgs/logo.webp' | asUrl }"
></div>
