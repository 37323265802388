import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class PollsService {
  constructor(private apiService: ApiService) {}

  getList() {
    return this.apiService.request(`/polls`, 'GET');
  }

  get(userId: string) {
    return this.apiService.request(`/polls/user/${userId}`, 'GET');
  }

  create(poll: any) {
    return this.apiService.request('/polls', 'POST', poll);
  }

  update(poll: any) {
    return this.apiService.request(`/polls/${poll._id}`, 'POST', poll);
  }
}
