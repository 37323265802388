import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AmplitudeService } from '../../services/amplitude.service';
import { AuthService } from '../../services/auth.service';
import { MatchesService } from '../../services/matches.service';
import { PollsService } from '../../services/polls.service';
import { SidenavService } from '../../services/sidenav.service';
import { TeamsService } from '../../services/teams.service';
import { UsersService } from '../../services/users.service';
import { MatchesListComponent } from '../shared/matches-list/matches-list.component';

@Component({
  selector: 'app-poll',
  templateUrl: './poll.component.html',
  styleUrls: ['./poll.component.scss'],
})
export class PollComponent implements OnInit {
  @ViewChild(MatchesListComponent)
  matchesListComponent: MatchesListComponent;
  @BlockUI() blockUI: NgBlockUI;

  sidenav: MatSidenav;
  user: any;
  userId: string;
  loggedUser: any;
  currentTimestamp: number;
  firstRoundIniTimestamp: number;
  currentRoundIniTimestamp: number;
  isAdmin: boolean;
  teams: any[];
  matchesViewMode: string;
  isMe: boolean = false;
  pollProcessed: boolean = false;

  private rounds: any[];
  private createMode: boolean = false;
  private pollId: string;

  constructor(
    private sidenavService: SidenavService,
    private usersService: UsersService,
    private route: ActivatedRoute,
    private snackbar: MatSnackBar,
    private authService: AuthService,
    private matchesService: MatchesService,
    private pollsService: PollsService,
    private teamsService: TeamsService,
    private router: Router,
    private amplitudeService: AmplitudeService
  ) {
    this.sidenav = this.sidenavService.getSidenav();
    this.loggedUser = this.authService.getUser();
    this.currentTimestamp = new Date().getTime() / 1000;
  }

  async ngOnInit() {
    this.teams = await this.teamsService.getList();
    this.userId = this.route.snapshot.params['id'];

    if (this.userId) {
      this.user = await this.usersService.get(this.userId);
    } else {
      this.user = this.loggedUser;
      this.userId = this.loggedUser._id;
    }

    this.isMe = this.user._id === this.loggedUser._id;
    this.matchesViewMode = this.isMe ? 'poll' : 'results';
    this.isAdmin = this.authService.isAdmin();
    this.rounds = await this.matchesService.getRounds();
    this.firstRoundIniTimestamp = this.rounds[0].iniTimestamp;
    this.currentRoundIniTimestamp = this.rounds[0].iniTimestamp;

    if (!this.user.jokers) {
      this.user.jokers = {
        champion: false,
        pichichi: false,
        matches: [],
      };
    }

    if (!this.isMe && this.currentTimestamp < this.firstRoundIniTimestamp) {
      this.snackbar.open(
        'No puedes ver los resultados de los demás hasta que no se haya iniciado la ronda',
        'Cerrar',
        { duration: 3000 }
      );
    }

    this.load();

    if (!this.isMe) {
      this.amplitudeService.logEvent('PollVisited', { user: this.user.email });
    }
  }

  toggleMenu() {
    this.sidenavService.toggle();
  }

  private async load() {
    const poll = await this.pollsService.get(this.user._id);

    if (!poll) {
      this.createMode = true;
      this.pollProcessed = true;
      return;
    }

    this.pollId = poll._id;
    this.populatePoll(poll);
  }

  private async populatePoll(poll: any) {
    this.createMode = false;

    if (!this.matchesListComponent?.matches) {
      setTimeout(() => {
        this.populatePoll(poll);
      }, 200);
      return;
    }

    poll.results.forEach((pollResult: any) => {
      const match = this.matchesListComponent.matches.find(
        (listMatch) => listMatch._id === pollResult.match._id
      );

      // If we are not viewing our own results, then check we are on a date after round starts
      if (!this.isMe && !this.isAdmin) {
        const roundIniTimestamp = this.rounds.find(
          (item) => item.name === match.round
        ).iniTimestamp;

        if (new Date().getTime() / 1000 < roundIniTimestamp) {
          match.homeGoals = null;
          match.awayGoals = null;
          match.penaltiesWinner = null;
          match.useJoker = null;
          match.points = null;
          return;
        }
      }

      match.homeGoals = pollResult.homeGoals;
      match.awayGoals = pollResult.awayGoals;
      match.penaltiesWinner = pollResult.penaltiesWinner;
      match.homeTeam.checked =
        match.penaltiesWinner &&
        match.penaltiesWinner._id === match.homeTeam._id;
      match.awayTeam.checked =
        match.penaltiesWinner &&
        match.penaltiesWinner._id === match.awayTeam._id;
      match.useJoker = this.user.jokers.matches.indexOf(match._id) !== -1;
      match.points = pollResult.points;

      // match.points = this.utilsService.calcMatchPointsFromResult(
      //   pollResult.match,
      //   pollResult,
      //   this.user
      // );
    });

    this.pollProcessed = true;
  }

  update() {
    let results = this.matchesListComponent.getResults();
    let poll = {
      _id: '',
      user: this.user,
      results: results,
    };

    if (!this.checkResults(results)) {
      return;
    }

    // Check results
    let error = false;

    poll.results.forEach((pollResult: any) => {
      if (
        (pollResult.homeGoals !== null && pollResult.awayGoals === null) ||
        (pollResult.homeGoals === null && pollResult.awayGoals !== null)
      ) {
        if (!error) {
          this.snackbar.open(
            'Tienes algún resultado a medio rellenar. Por favor, compruébalo.',
            'Cerrar',
            { duration: 3000 }
          );
          error = true;
        }
      }
    });

    if (error) {
      return;
    }

    this.blockUI.start();

    if (this.createMode) {
      this.pollsService
        .create(poll)
        .then(this.onSuccess.bind(this))
        .catch(this.onError.bind(this));
    } else {
      poll._id = this.pollId;
      this.pollsService
        .update(poll)
        .then(this.onSuccess.bind(this))
        .catch(this.onError.bind(this));
    }
  }

  private async onSuccess(poll: any) {
    this.blockUI.stop();
    this.user = await this.usersService.get(this.userId);
    await this.load();
    this.snackbar.open('Has actualizado los datos correctamente', 'Cerrar', {
      duration: 2000,
    });
    this.amplitudeService.logEvent('PollUpdated', { result: 'success' });
  }

  private onError(error: any) {
    this.blockUI.stop();

    if (error.code === 2001) {
      this.snackbar.open(
        'No puedes poner un JOKER aquí. Ya has superado el número máximo permitido.',
        'Cerrar',
        { duration: 2000 }
      );
      return;
    }

    this.snackbar.open(error.message, 'Cerrar', { duration: 2000 });
  }

  showChampionForm() {
    this.router.navigate([`/champion/${this.user._id}`]);
  }

  onRoundChanged(roundName: string) {
    const activeRoundIndex = this.rounds.findIndex(
      (round) => round.name === roundName
    );
    this.currentRoundIniTimestamp = this.rounds[activeRoundIndex].iniTimestamp;
  }

  checkResults(results: any[]) {
    let errorsFound = false;

    results.forEach((result: any) => {
      if (result.round !== 'GROUP_STAGE' && !this.isAdmin) {
        if (
          result.roundIniTimestamp > this.currentTimestamp &&
          result.homeGoals === result.awayGoals &&
          result.homeGoals !== null &&
          !result.penaltiesWinner
        ) {
          errorsFound = true;
          this.snackbar.open(
            'Hay algún partido en el que no has definido quién va a ganar por penalties.',
            'Cerrar',
            { duration: 4000 }
          );
          return false;
        }
      }

      return true;
    });

    return !errorsFound;
  }
}
