<mat-toolbar class="main-header">
  <button
    mat-icon-button
    *ngIf="sidenav.mode === 'over'"
    (click)="toggleMenu()"
  >
    <mat-icon *ngIf="!sidenav.opened"> menu </mat-icon>
    <mat-icon *ngIf="sidenav.opened"> close </mat-icon>
  </button>
  <span>Campeón y Pichichi</span>
</mat-toolbar>

<div class="content-padding form" *ngIf="user">
  <div class="field-wrapper">
    <mat-form-field>
      <mat-label>Campeón</mat-label>
      <mat-select
        [(ngModel)]="user.team._id"
        [disabled]="!isAdmin && firstRoundIniTimestamp < currentTimestamp"
      >
        <mat-option
          *ngFor="let team of teams"
          class="teams-select"
          [value]="team._id"
          ><img src="{{ team.flag }}" /> {{ team.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <!-- <mat-label>¿Usar Joker?</mat-label>
    <mat-slide-toggle
      checked="user.jokers?.champion"
      [(ngModel)]="user.jokers.champion"
      [disabled]="!isAdmin && firstRoundIniTimestamp < currentTimestamp"
    ></mat-slide-toggle> -->
  </div>

  <div class="field-wrapper">
    <mat-form-field>
      <mat-label>Pichichi</mat-label>
      <input
        matInput
        type="text"
        [(ngModel)]="user.pichichi"
        required
        [disabled]="!isAdmin && firstRoundIniTimestamp < currentTimestamp"
      />
    </mat-form-field>
    <!-- <mat-label>¿Usar Joker?</mat-label>
    <mat-slide-toggle
      checked="user.jokers?.pichichi"
      [(ngModel)]="user.jokers.pichichi"
      [disabled]="!isAdmin && firstRoundIniTimestamp < currentTimestamp"
    ></mat-slide-toggle> -->
  </div>
</div>

<mat-toolbar
  class="footer"
  *ngIf="isAdmin || (firstRoundIniTimestamp > currentTimestamp && isMe)"
>
  <button mat-raised-button color="primary" (click)="update()">Guardar</button>
</mat-toolbar>

<block-ui></block-ui>
