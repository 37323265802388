import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class GroupsService {
  constructor(private apiService: ApiService) {}

  getList() {
    return this.apiService.request('/groups', 'GET');
  }

  create(group: any) {
    return this.apiService.request('/groups/create', 'POST', group);
  }
}
