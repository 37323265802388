<div class="restore-wrapper">
  <mat-card class="form">
    <mat-card-header>
      <mat-card-title>Restablecer contraseña</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-form-field>
        <input matInput placeholder="Email" [(ngModel)]="username" />
      </mat-form-field>
      <mat-form-field *ngIf="code">
        <input
          type="password"
          matInput
          placeholder="Contraseña"
          [(ngModel)]="password"
        />
      </mat-form-field>
      <mat-form-field *ngIf="code">
        <input
          type="password"
          matInput
          placeholder="Repetir contraseña"
          [(ngModel)]="confirmPassword"
        />
      </mat-form-field>
    </mat-card-content>
    <button
      *ngIf="!code"
      mat-raised-button
      color="primary"
      class="btn-block"
      (click)="reset()"
    >
      Resetear
    </button>
    <button
      *ngIf="code"
      mat-raised-button
      color="primary"
      class="btn-block"
      (click)="restore()"
    >
      Restablecer
    </button>
  </mat-card>
</div>
<block-ui></block-ui>
