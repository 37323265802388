import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor() {}

  addUIPollsPropertiesToMatches(matches: any[], polls: any[], user: any) {
    matches.map((match: any) => {
      match.stats = {
        homeWin: 0,
        homeWinUsers: [],
        awayWin: 0,
        awayWinUsers: [],
        draw: 0,
        drawUsers: [],
        jokers: 0,
        jokersUsers: [],
      };

      polls.forEach((poll: any) => {
        if (!poll.user) {
          return;
        }

        const result = poll.results.find(
          (result: any) => result.match._id === match._id
        );

        if (!result) {
          return;
        }

        if (result.homeGoals === result.awayGoals) {
          match.stats.draw += 1;
          match.stats.drawUsers.push(poll.user);
        } else if (result.homeGoals > result.awayGoals) {
          match.stats.homeWin += 1;
          match.stats.homeWinUsers.push(poll.user);
        } else if (result.homeGoals < result.awayGoals) {
          match.stats.awayWin += 1;
          match.stats.awayWinUsers.push(poll.user);
        }

        if (poll.user.jokers?.matches?.includes(match._id)) {
          match.stats.jokers += 1;
          match.stats.jokersUsers.push(poll.user);
        }

        if (poll.user._id === user._id) {
          match.userHomeGoals = result.homeGoals;
          match.userAwayGoals = result.awayGoals;
          match.useJoker = user.jokers.matches.includes(match._id);
        }
      });
    });

    return matches;
  }
}
