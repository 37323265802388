<div mat-dialog-content>
  <h1>{{ title }}</h1>
  <p [innerHTML]="message"></p>
</div>
<div mat-dialog-actions>
  <button mat-stroked-button (click)="close()" *ngIf="cancelText">
    {{ cancelText }}
  </button>
  <button mat-stroked-button (click)="close()" *ngIf="!cancelText">
    Cancelar
  </button>
  <button
    mat-raised-button
    (click)="confirm()"
    color="accent"
    *ngIf="confirmText"
  >
    {{ confirmText }}
  </button>
  <button
    mat-raised-button
    (click)="confirm()"
    color="accent"
    *ngIf="!confirmText"
  >
    Sí
  </button>
</div>
