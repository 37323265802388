<mat-sidenav-container [hidden]="!isLogged">
  <mat-sidenav #sidenav="matSidenav" class="mat-elevation-z8">
    <div class="avatar-wrapper">
      <app-avatar [user]="user" class="mat-elevation-z8"></app-avatar>
      <input type="file" (change)="fileBrowseHandler($event)" name="avatar" />
    </div>

    <h4 class="name">{{ user?.name }}</h4>

    <mat-divider></mat-divider>

    <button mat-button class="menu-button" (click)="goToPage('/standings')">
      <mat-icon>format_list_numbered</mat-icon>
      <span>Clasificación</span>
    </button>

    <button mat-button class="menu-button" (click)="goToPage('/home')">
      <mat-icon>home</mat-icon>
      <span>Mi perfil</span>
    </button>

    <button mat-button class="menu-button" (click)="goToPage('/poll')">
      <mat-icon>info</mat-icon>
      <span>Resultados</span>
    </button>

    <mat-divider></mat-divider>

    <button
      mat-button
      class="menu-button"
      (click)="goToPage('/admin')"
      *ngIf="isAdmin"
    >
      <mat-icon>admin_panel_settings</mat-icon>
      <span>Administración</span>
    </button>

    <button mat-button class="menu-button" (click)="goToPage('/rules')">
      <mat-icon>help</mat-icon>
      <span>Reglas</span>
    </button>

    <button mat-button class="menu-button" (click)="goToPage('/login')">
      <mat-icon>logout</mat-icon>
      <span>Salir</span>
    </button>

    <mat-divider></mat-divider>

    <button mat-button class="menu-button">v{{ version }}</button>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
