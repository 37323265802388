<mat-toolbar class="main-header">
  <button
    mat-icon-button
    *ngIf="sidenav.mode === 'over'"
    (click)="toggleMenu()"
  >
    <mat-icon *ngIf="!sidenav.opened"> menu </mat-icon>
    <mat-icon *ngIf="sidenav.opened"> close </mat-icon>
  </button>
  <span>{{ title }}</span>
  <span class="spacer"></span>
  <mat-icon (click)="showRules()">help</mat-icon>
</mat-toolbar>

<div class="content">
  <div class="header">
    <div class="cover"></div>
    <div class="user-info">
      <div class="avatar-wrapper">
        <app-avatar [user]="user" class="mat-elevation-z8"></app-avatar>
        <input
          type="file"
          (change)="fileBrowseHandler($event)"
          name="avatar"
          *ngIf="isMe || isAdmin"
        />
      </div>

      <div class="name">{{ user?.name || user?.email }}</div>
      <div class="pos" *ngIf="standings">{{ standings.pos }}a. posición</div>
    </div>
  </div>

  <div class="poll" (click)="showPoll()" *ngIf="user">
    <!-- Champion -->
    <div
      class="team"
      *ngIf="isMe || currentTimestamp >= firstRoundIniTimestamp"
    >
      <ion-avatar
        ><img *ngIf="user.team" src="{{ user.team.flag }}"
      /></ion-avatar>
      <ion-avatar
        ><img *ngIf="!user.team" src="assets/imgs/logo.png"
      /></ion-avatar>
      <p *ngIf="user.team">{{ user.team.name }}</p>
      <p *ngIf="!user.team && isMe">
        <a>Configurar</a>
      </p>
      <div
        class="home-poll-check"
        *ngIf="user.team?._id === finalResult?.team._id"
      >
        <ion-icon name="checkmark-circle"></ion-icon>
      </div>
    </div>

    <div
      class="team"
      *ngIf="!isMe && currentTimestamp < firstRoundIniTimestamp"
    >
      <ion-avatar><img src="assets/imgs/logo.png" /></ion-avatar>
      <p>¡Espera al inicio!</p>
    </div>

    <!-- Pichichi -->
    <div
      class="pichichi"
      *ngIf="isMe || currentTimestamp >= firstRoundIniTimestamp"
    >
      <ion-avatar><img src="assets/imgs/pichichi.png" /></ion-avatar>
      <p *ngIf="user.pichichi">{{ user.pichichi }}</p>
      <p *ngIf="!user.pichichi && isMe">
        <a>Configurar</a>
      </p>
      <div
        class="home-poll-check"
        *ngIf="user?.pichichi === finalResult?.pichichi"
      >
        <ion-icon name="checkmark-circle"></ion-icon>
      </div>
    </div>

    <div
      class="pichichi"
      *ngIf="!isMe && currentTimestamp < firstRoundIniTimestamp"
    >
      <ion-avatar><img src="assets/imgs/pichichi.png" /></ion-avatar>
      <p>¡Espera al inicio!</p>
    </div>
  </div>

  <div class="stats" (click)="showPoll()">
    <div>
      <div class="value">{{ standings?.points }}</div>
      <div class="title">Puntos</div>
    </div>
    <div>
      <div class="value">{{ standings?.matchesOk }}</div>
      <div class="title">Aciertos</div>
    </div>
    <div>
      <div class="value">{{ standings?.sniper }}</div>
      <div class="title">Dianas</div>
    </div>
  </div>
</div>

<mat-toolbar class="footer" *ngIf="user">
  <button mat-raised-button color="primary" (click)="showPoll()">
    Ver Resultados
  </button>
</mat-toolbar>
