import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import packageJson from '../../package.json';
import { AmplitudeService } from './services/amplitude.service';
import { ApiService } from './services/api.service';
import { AuthService } from './services/auth.service';
import { LocalService } from './services/local.service';
import { SidenavService } from './services/sidenav.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;

  isLogged: boolean = false;
  title = 'World Cup 2022';
  user: any;
  isAdmin: boolean = false;
  version: string = packageJson.version;

  constructor(
    private observer: BreakpointObserver,
    private authService: AuthService,
    private sidenavService: SidenavService,
    private apiService: ApiService,
    private localService: LocalService,
    private router: Router,
    private amplitudeService: AmplitudeService
  ) {
    this.initializeApp();
    this.initAuth();
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.sidenavService.setSidenav(this.sidenav);
    this.observer
      .observe(['(max-width: 800px)'])
      .subscribe(this.checkMenuPosition);
  }

  private async initializeApp() {
    this.isLogged = false;
    this.amplitudeService.initializeAPI();
    this.initRouterSubscription();
    this.startAuthListener();
  }

  private startAuthListener() {
    this.authService.changed.subscribe(async (authenticated: boolean) => {
      this.isLogged = authenticated;
      this.user = this.authService.getUser();
      this.isAdmin = this.authService.isAdmin();
      this.checkMenuPosition(window.matchMedia('(max-width: 800px)'));

      if (this.isLogged) {
        this.amplitudeService.setUser();
      }
    });
  }

  private async initAuth() {
    const isAuthUrl =
      window.location.href.indexOf('/login') === -1 &&
      window.location.href.indexOf('/register') === -1;

    if (!isAuthUrl) {
      return;
    }

    this.apiService.setToken(this.localService.getData('token'));

    this.authService
      .checkValidToken()
      .then(() => {
        this.isLogged = true;
      })
      .catch(() => {
        this.isLogged = false;
      });
  }

  private initRouterSubscription() {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.amplitudeService.logEvent('Navigation', { page: event.url });
      }
    });
  }

  goToPage(path: string) {
    this.router.navigate([path], {
      replaceUrl: true,
    });

    this.checkMenuPosition(window.matchMedia('(max-width: 800px)'));
  }

  private checkMenuPosition(res: any) {
    if (!this.isLogged) {
      return;
    }

    if (res.matches) {
      this.sidenav.mode = 'over';
      this.sidenav.close();
    } else {
      this.sidenav.mode = 'side';
      this.sidenav.open();
    }
  }

  /**
   * Handle file from browsing
   */
  fileBrowseHandler(event: any) {
    const files = event.target.files;

    if (files.length === 1 && files[0].type.indexOf('image/') === 0) {
      const fileObj = files[0];

      this.apiService
        .uploadFile(`/users/upload-avatar/${this.user._id}`, fileObj)
        .then((user) => {
          this.user = user;
        });
    }
  }
}
