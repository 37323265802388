import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private apiService: ApiService) {}

  getList() {
    return this.apiService.request('/users', 'GET');
  }

  get(userId: string) {
    return this.apiService.request(`/users/${userId}`, 'GET');
  }

  update(user: any) {
    return this.apiService.request(`/users/update`, 'POST', user);
  }

  sendPaymentReminders() {
    return this.apiService.request(`/users/payment-reminders`, 'POST');
  }

  delete(userId: string) {
    return this.apiService.request(`/users/${userId}`, 'DELETE');
  }
}
