<mat-toolbar class="main-header">
  <button
    mat-icon-button
    *ngIf="sidenav.mode === 'over'"
    (click)="toggleMenu()"
  >
    <mat-icon *ngIf="!sidenav.opened"> menu </mat-icon>
    <mat-icon *ngIf="sidenav.opened"> close </mat-icon>
  </button>
  <span>{{ user?.name }}</span>
  <span class="spacer"></span>
  <button mat-raised-button color="basic" (click)="showChampionForm()">
    Campeón / Pichichi
  </button>
</mat-toolbar>

<div class="content">
  <app-matches-list
    *ngIf="user"
    [hidden]="!pollProcessed"
    [mode]="matchesViewMode"
    [user]="user"
    (roundChanged)="onRoundChanged($event)"
  ></app-matches-list>
</div>

<mat-toolbar
  class="footer"
  *ngIf="isAdmin || (currentRoundIniTimestamp > currentTimestamp && isMe)"
>
  <button mat-raised-button color="primary" (click)="update()">Guardar</button>
</mat-toolbar>

<block-ui></block-ui>
