import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { SidenavService } from '../../services/sidenav.service';
import { UsersService } from '../../services/users.service';
import { ConfirmModalComponent } from '../shared/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  sidenav: MatSidenav;
  users: any[];

  constructor(
    private sidenavService: SidenavService,
    private usersService: UsersService,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.sidenav = this.sidenavService.getSidenav();
  }

  async ngOnInit() {
    this.users = await this.usersService.getList();
  }

  toggleMenu() {
    this.sidenavService.toggle();
  }

  editUser(user: any) {
    this.router.navigate([`/admin/users/${user._id}`]);
  }

  togglePayment(user: any) {
    user.hasPaid = !user.hasPaid;
    this.usersService.update(user);
  }

  sendPaymentReminders() {
    this.usersService.sendPaymentReminders();
  }

  delete(user: any) {
    // Show confirmation modal and then execute api call
    this.dialog
      .open(ConfirmModalComponent, {
        data: {
          title: 'Elimninar usuario',
          message: '¿Estás seguro de que quieres eliminar este usuario?',
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (!result) {
          return;
        }

        this.usersService.delete(user._id);
      });
  }
}
