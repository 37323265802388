import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AmplitudeService } from '../../services/amplitude.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  username: string = '';
  password: string = '';

  constructor(
    private authService: AuthService,
    private snackbar: MatSnackBar,
    private router: Router,
    private amplitudeService: AmplitudeService
  ) {}

  ngOnInit(): void {
    this.authService.clean();
  }

  login(): void {
    if (!this.username || !this.password) {
      this.snackbar.open('Por favor, rellena todos los campos', 'Cerrar', {
        duration: 3000,
      });

      return;
    }

    this.blockUI.start();
    this.authService
      .login(this.username, this.password)
      .then(() => {
        this.blockUI.stop();
        this.amplitudeService.logEvent('Login', { result: 'success' });
        this.router.navigate(['/home'], {
          replaceUrl: true,
        });
      })
      .catch((error) => {
        this.blockUI.stop();
        this.snackbar.open(error.message, 'Cerrar', { duration: 5000 });
      });
  }

  showRegister() {
    this.router.navigate(['/register']);
  }

  showRestore() {
    this.router.navigate(['/restore']);
  }
}
