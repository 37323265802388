<div class="login-wrapper">
  <div class="logo">
    <img src="assets/logo.png" alt="logo" />
  </div>
  <div class="form-wrapper">
    <mat-card class="form">
      <mat-card-header>
        <mat-card-title>Login</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-form-field>
          <input matInput placeholder="Email" [(ngModel)]="username" />
        </mat-form-field>
        <mat-form-field>
          <input
            type="password"
            matInput
            placeholder="Contraseña"
            [(ngModel)]="password"
          />
        </mat-form-field>
      </mat-card-content>
      <button
        mat-raised-button
        color="primary"
        class="btn-block"
        (click)="login()"
      >
        Entrar
      </button>

      <button mat-button (click)="showRegister()" color="accent">
        ¿Eres nuevo? Regístrate
      </button>

      <button mat-button (click)="showRestore()" color="accent">
        ¿Olvidaste tu contraseña?
      </button>
    </mat-card>
  </div>
</div>
<block-ui></block-ui>
