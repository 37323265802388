<mat-toolbar class="main-header">
  <button
    mat-icon-button
    *ngIf="sidenav.mode === 'over'"
    (click)="toggleMenu()"
  >
    <mat-icon *ngIf="!sidenav.opened"> menu </mat-icon>
    <mat-icon *ngIf="sidenav.opened"> close </mat-icon>
  </button>
  <span>Clasificación</span>
  <span class="spacer"></span>
  <button mat-raised-button color="basic" (click)="reload()">
    <mat-icon>refresh</mat-icon>
  </button>
</mat-toolbar>

<div class="content">
  <div *ngIf="liveMatches && liveMatches.length > 0" class="live-matches">
    <h3>Partidos en juego</h3>
    <ion-card *ngFor="let match of liveMatches">
      <ion-card-content class="live-match">
        <div class="wrapper">
          <div class="home">
            <div class="team-logo">
              <img src="{{ match.homeTeam.flag }}" />
            </div>
            <!-- <div class="team-name">{{ match.homeTeam.name }}</div> -->
            <div class="goals">{{ match.homeGoals }}</div>
          </div>
          <div>vs.</div>
          <div class="away">
            <div class="goals">{{ match.awayGoals }}</div>
            <!-- <div class="team-name">{{ match.awayTeam.name }}</div> -->
            <div class="team-logo">
              <img src="{{ match.awayTeam.flag }}" />
            </div>
          </div>
        </div>

        <div class="match-stats">
          <div
            class="stat-wrapper"
            (click)="showUsers(match.stats.homeWinUsers)"
          >
            <mat-chip>{{ match.stats?.homeWin }}</mat-chip>
            <span>1</span>
          </div>
          <div class="stat-wrapper" (click)="showUsers(match.stats.drawUsers)">
            <mat-chip>{{ match.stats?.draw }}</mat-chip>
            <span>X</span>
          </div>
          <div
            class="stat-wrapper"
            (click)="showUsers(match.stats.awayWinUsers)"
          >
            <mat-chip>{{ match.stats?.awayWin }}</mat-chip>
            <span>2</span>
          </div>
          <div
            class="stat-wrapper"
            (click)="showUsers(match.stats.jokersUsers)"
          >
            <mat-chip>{{ match.stats?.jokers }}</mat-chip>
            <img src="/assets/imgs/joker-on.svg" />
          </div>
        </div>

        <div class="my-result">
          <span>{{ match.userHomeGoals }} - {{ match.userAwayGoals }}</span>
          <img *ngIf="match.useJoker" src="/assets/imgs/joker-on.svg" />
        </div>
      </ion-card-content>
    </ion-card>
  </div>

  <mat-card
    class="standings-card"
    id="standings-{{ standing.user._id }}"
    *ngFor="let standing of standings; let i = index"
    (click)="selectUser(standing.user)"
    [class.me]="standing.user._id == loggedUser._id"
  >
    <div
      *ngIf="currentTimestamp >= firstRoundIniTimestamp && standing.user.team"
      class="champion-bg"
      [ngStyle]="{ 'background-image': standing.user.team.flag | asUrl }"
    ></div>
    <div class="cover st-content">
      <div class="avatar">
        <app-avatar
          [user]="standing.user"
          class="mat-elevation-z8"
        ></app-avatar>
        <mat-badge>{{ i + 1 }}</mat-badge>
      </div>
      <h2>{{ standing.user.name }}</h2>
      <div class="position-change">
        <div
          [class.equal]="standing.posChange.change === 'equal'"
          [class.up]="standing.posChange.change === 'up'"
          [class.down]="standing.posChange.change === 'down'"
        ></div>
        {{ standing.posChange.number }}
      </div>
      <div class="points" item-end>
        <div>{{ standing.points }}</div>
        <div class="jokers">
          {{ standing.jokersConsumed }}<img src="/assets/imgs/joker-on.svg" />
        </div>
      </div>
      <div class="live-results" *ngIf="standing.currentResults">
        <div class="result" *ngFor="let liveResult of standing.currentResults">
          <span> {{ liveResult.homeGoals }} - {{ liveResult.awayGoals }} </span>
          <img *ngIf="liveResult.useJoker" src="/assets/imgs/joker-on.svg" />
        </div>
      </div>
    </div>
    <div
      class="pichichi"
      *ngIf="
        currentTimestamp >= firstRoundIniTimestamp && standing.user.pichichi
      "
    >
      {{ standing.user.pichichi }}
    </div>
  </mat-card>
</div>

<mat-toolbar class="footer">
  <mat-select [(ngModel)]="groupSelected">
    <mat-option (click)="selectGroup('general')" [value]="'general'"
      >General</mat-option
    >
    <mat-option
      (click)="selectGroup(group._id)"
      *ngFor="let group of groups"
      [value]="group._id"
      >{{ group.name }}</mat-option
    >
  </mat-select>
  <!-- <mat-chip-list
    aria-label="Round selection"
    [selectable]="true"
    [multiple]="false"
  >
    <mat-chip
      (click)="selectGroup('general')"
      [selected]="'general' === groupSelected"
      >GENERAL</mat-chip
    >
    <mat-chip
      *ngFor="let group of groups"
      (click)="selectGroup(group._id)"
      [selected]="group._id === groupSelected"
      >{{ group.name }}</mat-chip
    >
  </mat-chip-list> -->
</mat-toolbar>

<block-ui></block-ui>
