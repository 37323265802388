<mat-toolbar class="main-header">
  <button
    mat-icon-button
    *ngIf="sidenav.mode === 'over'"
    (click)="toggleMenu()"
  >
    <mat-icon *ngIf="!sidenav.opened"> menu </mat-icon>
    <mat-icon *ngIf="sidenav.opened"> close </mat-icon>
  </button>
  <span>Grupos</span>
  <span class="spacer"></span>
  <mat-icon (click)="createGroup()">add</mat-icon>
</mat-toolbar>

<div class="content">
  <mat-accordion>
    <mat-expansion-panel *ngFor="let group of groups">
      <mat-expansion-panel-header>{{ group.name }}</mat-expansion-panel-header>
      <div class="users">
        <mat-list>
          <mat-list-item *ngFor="let user of usersByGroup[group.name]">
            <app-avatar matListAvatar [user]="user"></app-avatar>
            <h4>{{ user.name }}</h4>
            <mat-icon [matMenuTriggerFor]="groupsMenu">people</mat-icon>

            <mat-menu #groupsMenu="matMenu">
              <button mat-menu-item (click)="changeGroup(user)">
                Sin Grupo
              </button>
              <button
                mat-menu-item
                *ngFor="let group of groups"
                (click)="changeGroup(user, group._id)"
              >
                {{ group.name }}
              </button>
            </mat-menu>
          </mat-list-item>
        </mat-list>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>SIN GRUPO</mat-expansion-panel-header>
      <div class="users">
        <mat-list>
          <mat-list-item *ngFor="let user of usersByGroup['NOGROUP']">
            <app-avatar matListAvatar [user]="user"></app-avatar>
            <h4>{{ user.name }}</h4>
            <mat-icon [matMenuTriggerFor]="groupsMenu">people</mat-icon>
            <mat-menu #groupsMenu="matMenu">
              <button mat-menu-item (click)="changeGroup(user)">
                Sin Grupo
              </button>
              <button
                mat-menu-item
                *ngFor="let group of groups"
                (click)="changeGroup(user, group._id)"
              >
                {{ group.name }}
              </button>
            </mat-menu>
          </mat-list-item>
        </mat-list>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<block-ui></block-ui>
