import { EventEmitter, Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { LocalService } from './local.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  changed: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private apiService: ApiService,
    private localService: LocalService
  ) {}

  checkValidToken() {
    return new Promise((resolve, reject) => {
      const token = this.localService.getData('token');
      this.apiService
        .request(`/auth/valid/${token}`, 'GET')
        .then((response) => {
          this.setAuthData(response);
          resolve(true);
        })
        .catch((error) => {
          this.clean();
          reject();
        });
    });
  }

  login(username: string, password: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService
        .request('/auth/login', 'POST', {
          username,
          password,
        })
        .then((response) => {
          if (response.error) {
            reject(response);
            return;
          }

          this.setAuthData(response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  restorePassword(username: string, password: string, code: string) {
    return new Promise<any>((resolve, reject) => {
      this.apiService
        .request('/auth/restore-password', 'POST', {
          username,
          password,
          code,
        })
        .then((response) => {
          if (response.error) {
            reject(response);
            return;
          }

          this.setAuthData(response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  resetPassword(username: string) {
    return new Promise<any>((resolve, reject) => {
      this.apiService
        .request('/auth/remember', 'POST', {
          username,
        })
        .then((response) => {
          if (response.error) {
            reject(response);
            return;
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  private setAuthData(data: any) {
    this.localService.saveData('token', data.token);
    this.localService.saveData('user', data.user);
    this.apiService.setToken(data.token);
    this.changed.emit(true);
  }

  clean() {
    this.localService.removeData('user');
    this.localService.removeData('token');
    this.apiService.setToken('');
    this.changed.emit(false);
  }

  isLogged() {
    return this.localService.getData('token') !== null;
  }

  getUser() {
    return this.localService.getData('user');
  }

  register(data: any) {
    return this.apiService.request('/auth/signup', 'POST', data);
  }

  isAdmin() {
    const user = this.getUser();
    return user?.role === 1;
  }
}
